var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sys-setting-page" },
    [
      _c(
        "div",
        { staticClass: "pay-switch" },
        [
          _c("a-switch", {
            attrs: { "checked-children": "开", "un-checked-children": "关" },
            model: {
              value: _vm.payStatus,
              callback: function ($$v) {
                _vm.payStatus = $$v
              },
              expression: "payStatus",
            },
          }),
          _c("div", { staticClass: "func-tip" }, [
            _c("div", [
              _c("b", [
                _vm._v(
                  "【" + _vm._s(_vm.payStatus ? "已开启" : "已关闭") + "】"
                ),
              ]),
              _vm._v("微信零钱支付模式 "),
            ]),
            _c("div", [
              _vm._v(
                " 这是一个全局开关，会影响系统中所有涉及付款相关功能，包括智能秤App、小程序、PC端后台中涉及付款方式功能。 "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "price-mode" },
        [
          _c("div", { staticClass: "head-title" }, [_vm._v(" 价格模式开关 ")]),
          _vm._m(0),
          _vm.cMounted
            ? _c(
                "a-checkbox-group",
                {
                  on: { change: _vm.onChange },
                  model: {
                    value: _vm.defaultValue,
                    callback: function ($$v) {
                      _vm.defaultValue = $$v
                    },
                    expression: "defaultValue",
                  },
                },
                _vm._l(_vm.plainOptions, function (item, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _c(
                        "a-checkbox",
                        { attrs: { value: item.key, "default-checked": true } },
                        [
                          _c("span", { staticClass: "check-item" }, [
                            _c("b", [_vm._v(_vm._s(item.title))]),
                            _vm._v("， " + _vm._s(item.value) + " "),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "a-button",
        {
          staticClass: "s-btn",
          attrs: { type: "primary" },
          on: { click: _vm.sysSettingUpdate },
        },
        [_vm._v(" 保存 ")]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hint" }, [
      _vm._v(" 设置系统所支持的价格模式，"),
      _c("b", [_vm._v("至少需要打开一种")]),
      _vm._v(
        "价格模式。打开相应价格模式后，工作人员才能在智能秤上使用该模式。 "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }