/*
 * @Auth: linjituan
 * @Date: 2022-07-27 19:35:15
 * @LastEditors: linjituan
 * @LastEditTime: 2022-07-27 19:36:18
 */
import request from '@/utils/request'
const baseURL = '/rc-api'

// 获取系统设定参数
export function sysSettingList(query) {
  return request({
    url: '/internal/recyclable/sysSetting/list',
    baseURL,
    method: 'get',
    params: query
  })
}

// 更新系统设定参数
export function sysSettingUpdate(query) {
  return request({
    url: '/internal/recyclable/sysSetting/update',
    baseURL,
    method: 'get',
    params: query
  })
}
