<!--
 * @Auth: linjituan
 * @Date: 2022-07-25 17:59:18
 * @LastEditors: linjituan
 * @LastEditTime: 2022-08-11 17:39:27
-->
<template>
  <div class="sys-setting-page">
    <div class="pay-switch">
      <a-switch v-model="payStatus" checked-children="开" un-checked-children="关" />
      <div class="func-tip">
        <div>
          <b>【{{ payStatus ? '已开启' : '已关闭' }}】</b>微信零钱支付模式
        </div>
        <div>
          这是一个全局开关，会影响系统中所有涉及付款相关功能，包括智能秤App、小程序、PC端后台中涉及付款方式功能。
        </div>
      </div>
    </div>
    <div class="price-mode">
      <div class="head-title">
        价格模式开关
      </div>
      <div class="hint">
        设置系统所支持的价格模式，<b>至少需要打开一种</b>价格模式。打开相应价格模式后，工作人员才能在智能秤上使用该模式。
      </div>
      <a-checkbox-group v-model="defaultValue" @change="onChange" v-if="cMounted">
        <div v-for="(item, index) in plainOptions" :key="index">
          <a-checkbox :value="item.key" :default-checked="true">
            <span class="check-item">
              <b>{{ item.title }}</b
              >，
              {{ item.value }}
            </span>
          </a-checkbox>
        </div>
      </a-checkbox-group>
    </div>
    <a-button class="s-btn" type="primary" @click="sysSettingUpdate">
      保存
    </a-button>
  </div>
</template>

<script>
import { sysSettingList, sysSettingUpdate } from '@/api/recyclable/sysSetting'
export default {
  data() {
    return {
      payStatus: false,
      checkedList: [],
      plainOptions: [
        {
          key: 'stationPriceSwitch',
          title: '站点价格',
          value: '居民携带回收物前往站点进行交易的价格。',
          checked: false
        },
        {
          key: 'smPriceSwitch',
          title: '上门价格',
          value: '居民在家中，等待工作人员上门进行交易时价格。',
          checked: false
        },
        {
          key: 'selfSettingPriceSwitch',
          title: '自主设置价格',
          value: '工作人员可以在智能秤上自主设置回收物价格，但是不能超过后台设置的价格区间。',
          checked: false
        }
      ],
      settingInfo: {},
      cMounted: false,
      defaultValue: []
    }
  },
  mounted() {
    this.getDefauletSetting()
  },
  methods: {
    onChange(e) {
      console.log('onChange', e, this.plainOptions)
      for (const item of this.plainOptions) {
        item.checked = false
        if (e.includes(item.key)) {
          item.checked = true
        }
      }
    },
    getDefauletSetting() {
      sysSettingList()
        .then(res => {
          this.settingInfo = res.data
          this.payStatus = !!this.settingInfo.wxPaySwitch
          for (const item of this.plainOptions) {
            if (this.settingInfo[item.key]) {
              this.defaultValue.push(item.key)
            }
            item.checked = !!this.settingInfo[item.key]
          }
          this.cMounted = true
        })
        .catch(() => {
          this.cMounted = true
        })
    },
    checkModelFlag() {
      let flag = false
      for (const item of this.plainOptions) {
        if (item.checked) {
          flag = true
        }
      }
      return flag
    },
    sysSettingUpdate() {
      const flag = this.checkModelFlag()
      if (!flag) {
        this.$message.error('保存失败，请至少打开一种价格模式，再保存')
        return
      }
      sysSettingUpdate({
        selfSettingPriceSwitch: this.plainOptions[2].checked ? 1 : 0,
        smPriceSwitch: this.plainOptions[1].checked ? 1 : 0,
        stationPriceSwitch: this.plainOptions[0].checked ? 1 : 0,
        wxPaySwitch: this.payStatus ? 1 : 0,
        id: this.settingInfo.id
      }).then(res => {
        this.$message.success('保存成功', 3)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.sys-setting-page {
  background: #fff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .pay-switch {
    display: flex;
    .func-tip {
      margin-left: 15px;
      font-size: 16px;
    }
  }
  .price-mode {
    margin-top: 15px;
    .head-title {
      font-size: 20px;
      font-weight: bold;
      padding: 10px 0;
    }
    .hint {
      margin-bottom: 10px;
      font-size: 16px;
    }
    .check-item {
      font-size: 16px;
    }
  }
  .s-btn {
    margin-top: 30px;
    width: 200px;
    height: 40px;
    line-height: 40px;
  }
}
</style>
